import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private loaded = false;

  constructor() {}

  loadScript() {
    if (!this.loaded) {
      const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places&language=fr`;

      // Check if script with the Google Maps URL already exists
      const scripts = Array.from(document.getElementsByTagName('script'));
      const alreadyLoaded = scripts.some(script => script.src === mapUrl);

      if (!alreadyLoaded) {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;

        script.src = mapUrl;
        document.head.appendChild(script);
        this.loaded = true;
      }
    }
  }
}
