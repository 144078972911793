import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  interval = interval(120000);
  intervalSubscription!: Subscription;
  private broadcastChannel = new BroadcastChannel('sw-updates');
  private updateAvailable = new BehaviorSubject<boolean>(false);
  isUpdateAvailable$ = this.updateAvailable.asObservable();
  private checkingUpdates = true;

  constructor(
    private swUpdate: SwUpdate,
    private zone: NgZone
  ) {
    this.checkForUpdates();
    this.setupVisibilityChangeHandler();
    this.listenToBroadcastChannel();
  }

  setupVisibilityChangeHandler() {
    document.addEventListener('visibilitychange', () => {
      if (
        document.visibilityState === 'visible' &&
        !this.updateAvailable.getValue()
      ) {
        this.broadcastChannel.postMessage({ type: 'check-update' });
      }
    });
  }

  listenToBroadcastChannel() {
    this.broadcastChannel.onmessage = event => {
      if (event.data.type === 'update-available') {
        this.updateAvailable.next(true);
        this.stopCheckingUpdates();
      }
    };
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled && this.checkingUpdates) {
      this.intervalSubscription = this.interval.subscribe(() => {
        this.zone.runOutsideAngular(() => {
          this.checkForUpdateOutsideZone();
        });
      });
    }
  }

  stopCheckingUpdates() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.checkingUpdates = false;
    }
  }

  private checkForUpdateOutsideZone(): void {
    this.swUpdate
      .checkForUpdate()
      .then((isUpdateAvailable: boolean) => {
        if (isUpdateAvailable) {
          this.notifyUpdateAvailable();
        }
      })
      .catch(err => {
        console.error('AppUpdateService: Error checking for updates', err);
      });
  }

  private notifyUpdateAvailable(): void {
    this.zone.run(() => {
      this.broadcastChannel.postMessage({
        type: 'update-available',
      });
      this.updateAvailable.next(true);
      this.stopCheckingUpdates();
    });
  }
}
