import { Injectable } from '@angular/core';
import { CabinetsInterface } from '@features/cabinets/domain/entities/cabinets.interface';
import { CabinetsApiPorts } from '@features/cabinets/domain/ports/cabinets-api.ports';
import { LoadSilenceCabinets } from '@features/cabinets/domain/redux/actions/cabinets.action';
import { Action, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

export class CabinetsStateModel {
  cabinets: CabinetsInterface[] | undefined;
}

export const defaultCabinetsState: CabinetsStateModel = {
  cabinets: [],
};

@State<CabinetsStateModel>({
  name: 'cabinets',
  defaults: defaultCabinetsState,
})
@Injectable()
export class CabinetsState {
  constructor(public cabinetsService: CabinetsApiPorts) {}

  @Action(LoadSilenceCabinets)
  async loadSilenceCabinets(
    ctx: StateContext<CabinetsStateModel>
  ): Promise<void> {
    try {
      const cabinets = await lastValueFrom(this.cabinetsService.getCabinets());
      ctx.patchState({
        cabinets: cabinets,
      });
    } catch (error) {
      console.error('Erreur lors du chargement des cabinets', error);
    }
  }
}
