import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  SetActualUrl,
  SetPreviousUrl,
} from '@features/nav/domain/redux/actions/nav.actions';

@Injectable({
  providedIn: 'root',
})
export class UrlListenerService {
  private previousUrl!: string;

  constructor(
    router: Router,
    private store: Store
  ) {
    router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.store.dispatch(new SetActualUrl(event.urlAfterRedirects));

        if (this.previousUrl) {
          this.store.dispatch(new SetPreviousUrl(this.previousUrl));
        }

        this.previousUrl = event.urlAfterRedirects;
      });
  }
}
