import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewportService implements OnDestroy {
  private resizeSubject = new Subject<void>();
  resizeObservable = this.resizeSubject.asObservable();
  private boundAdjustVhUnit: () => void;

  constructor() {
    this.boundAdjustVhUnit = this.adjustVhUnit.bind(this);
    window.addEventListener('resize', this.boundAdjustVhUnit);
    this.adjustVhUnit(); // Assurez-vous d'appeler cela au démarrage pour initialiser
  }

  adjustVhUnit(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.resizeSubject.next();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.boundAdjustVhUnit);
  }
}
