import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatBadge } from '@angular/material/badge';
import { RouterLink } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ConsultantModule } from '@features/consultant/consultant.module';

@NgModule({
  declarations: [NavbarComponent, BottomNavComponent],
  exports: [NavbarComponent, BottomNavComponent],
  imports: [
    CommonModule,
    NgOptimizedImage,
    BrowserAnimationsModule,
    MatBadge,
    RouterLink,
    MatMenuModule,
    MatButtonModule,
    MatIcon,
    ConsultantModule,
  ],
})
export class NavbarModule {}
