import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@features/authentification/adapters/secondary/guard/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    data: { hideNavbar: true },
    loadChildren: () =>
      import('src/features/authentification/authentication.module').then(
        m => m.AuthenticationModule
      ),
    title: 'Connexion',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('src/features/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    title: 'Tableau de bord',
  },

  {
    path: 'mandats',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/mandats/mandats.module').then(m => m.MandatsModule),
    title: 'Mandats actifs',
  },
  {
    path: 'bon-de-visite',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/visit-form/visit-form.module').then(
        m => m.VisitFormModule
      ),
  },
  {
    path: 'buyers',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('src/features/buyers/buyers.module').then(m => m.BuyersModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
