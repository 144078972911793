@if (!fullScreenMenuOpen) {
  <div class="bottom-nav-closed" [@moveInAndOut]>
    <div class="nav-item">
      <img
        (click)="toggleFullScreenMenu()"
        (keyup.enter)="toggleFullScreenMenu()"
        tabindex="0"
        alt="Ouvrir/Fermer le menu"
        height="30"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 180deg"
        width="30" />
      <p class="icon-label">Menu</p>
    </div>

    <ng-container *ngIf="isMandatUpdateRoute(); else otherRoutes">
      <ng-container *ngTemplateOutlet="updateMandatIcons"></ng-container>
    </ng-container>

    <ng-template #otherRoutes>
      <ng-container [ngSwitch]="actualUrl">
        <ng-container *ngSwitchCase="'/mandats'">
          <ng-container *ngTemplateOutlet="mandatsIcons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'/mandats/prospections'">
          <ng-container *ngTemplateOutlet="prospectionsIcons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'/acquereurs'">
          <ng-container *ngTemplateOutlet="acquereursIcons"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="defaultIcons"></ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>

  <ng-template #defaultIcons>
    <div class="nav-item">
      <img
        alt="Mandat"
        height="30px"
        src="./assets/navbar/folder.svg"
        width="30px"
        [routerLink]="'/mandats'" />
      <p class="icon-label">Mandat</p>
    </div>
    <div class="nav-item">
      <img
        alt="Acquereurs"
        height="30px"
        src="./assets/navbar/people.svg"
        width="30px" />
      <p class="icon-label">Acquereurs</p>
    </div>
    <div class="nav-item">
      <img
        alt="Business"
        height="30px"
        src="./assets/navbar/hand-shake.svg"
        width="30px" />
      <p class="icon-label">Business</p>
    </div>
  </ng-template>
  <ng-template #mandatsIcons>
    <div class="nav-item">
      <img
        tabindex="0"
        (click)="navigationGoback()"
        (keyup.enter)="navigationGoback()"
        alt="Ouvrir/Fermer le menu"
        height="30"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="30" />
      <p class="icon-label">Retour</p>
    </div>
    <div class="nav-item">
      <img
        alt="Mandat"
        height="30px"
        src="./assets/navbar/new.png"
        width="30px"
        [routerLink]="'mandats/checking'"
        [queryParams]="{ prospection: 'false' }" />

      <p class="icon-label">Créer</p>
    </div>
    <div class="nav-item">
      <span
        [routerLink]="'bon-de-visite'"
        [matBadge]="this.numberOfSelectedMandatsInVisitForm"
        matBadgeColor="primary"
        matBadgeSize="small"
        [hidden]="this.numberOfSelectedMandatsInVisitForm === 0">
        <img
          alt="Mandat Icon"
          height="30px"
          src="./assets/icons/cart.svg"
          width="30px" />
      </span>
      <img
        *ngIf="this.numberOfSelectedMandatsInVisitForm === 0"
        [routerLink]="'bon-de-visite'"
        alt="Mandat Icon"
        height="30px"
        src="./assets/icons/cart.svg"
        width="30px" />
      <p class="icon-label">Bon de visite</p>
    </div>
  </ng-template>
  <ng-template #prospectionsIcons>
    <div class="nav-item">
      <img
        tabindex="0"
        (click)="navigationGoback()"
        (keyup.enter)="navigationGoback()"
        alt="Ouvrir/Fermer le menu"
        height="30"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="30" />
      <p class="icon-label">Retour</p>
    </div>
    <div class="nav-item">
      <img
        alt="Mandat"
        height="30px"
        src="./assets/navbar/new.png"
        width="30px"
        [routerLink]="'mandats/checking'"
        [queryParams]="{ prospection: true }" />
      <p class="icon-label">Créer</p>
    </div>
    <div class="nav-item">
      <img
        alt="Mandat"
        height="30px"
        src="./assets/navbar/extract.png"
        width="30px" />
      <p class="icon-label">Exporter</p>
    </div>
  </ng-template>
  <ng-template #acquereursIcons>
    <div class="nav-item">
      <img
        tabindex="0"
        (click)="navigationGoback()"
        (keyup.enter)="navigationGoback()"
        alt="Ouvrir/Fermer le menu"
        height="30"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="30" />
      <p class="icon-label">Retour</p>
    </div>
    <img
      alt="Acquereurs Icon"
      height="30px"
      [routerLink]="'/buyers'"
      src="./assets/navbar/acquereurs-people.svg"
      width="30px" />
  </ng-template>

  <ng-template #updateMandatIcons>
    <div class="nav-item">
      <img
        tabindex="0"
        (click)="navigationGoback()"
        (keyup.enter)="navigationGoback()"
        alt="Ouvrir/Fermer le menu"
        height="30"
        ngSrc="./assets/icons/arrow.svg"
        style="rotate: 90deg"
        width="30" />
      <p class="icon-label">Retour</p>
    </div>
    <div class="nav-item">
      <img
        alt="Acquereurs Icon"
        height="30px"
        src="./assets/navbar/save.svg"
        width="30px" />
      <p>Enregistrers</p>
    </div>
  </ng-template>
}
<div
  *ngIf="fullScreenMenuOpen"
  [@menuState]="fullScreenMenuOpen ? 'open' : 'closed'"
  class="opened-menu-container">
  <p>Tableau de bord</p>
  <p>Mandat</p>
  <ul>
    <li
      (click)="toggleFullScreenMenu()"
      (keyup.enter)="toggleFullScreenMenu()"
      [routerLink]="['/mandats']"
      tabindex="0">
      Mandat actif
    </li>
    <li
      (click)="toggleFullScreenMenu()"
      (keyup.enter)="toggleFullScreenMenu()"
      [routerLink]="['/mandats/prospections']"
      tabindex="0">
      Fichier de prospection
    </li>
    <li>Bailleur</li>
    <li
      (click)="toggleFullScreenMenu()"
      (keyup.enter)="toggleFullScreenMenu()"
      [queryParams]="{ prospection: false }"
      [routerLink]="['/mandats/checking']"
      tabindex="0">
      Créer un pré-mandat
    </li>
    <li
      (click)="toggleFullScreenMenu()"
      (keyup.enter)="toggleFullScreenMenu()"
      [queryParams]="{ prospection: true }"
      [routerLink]="['/mandats/checking']"
      tabindex="0">
      Créer une fiche de prospection
    </li>
  </ul>
  <p>Acquereurs</p>
  <ul>
    <li>Mes acquéreurs</li>
    <li>Nos acquéreurs</li>
    <li>Créer un acquéreur</li>
  </ul>
  <p>Business</p>
  <ul>
    <li>Affaires en cours</li>
    <li>Factures</li>
    <li>Créer une offre d'achat</li>
  </ul>
  <p>Archives</p>
  <ul>
    <li>Mandats archivés</li>
    <li>Acquéreurs archivés</li>
    <li>Affaires traitées</li>
  </ul>

  <p>Administration agence</p>
  <ul>
    <li>Reporting utilisateurs</li>
    <li>Passerelles le bon coin</li>
    <li>Téléchargement</li>
  </ul>

  <p>Agences</p>
  <ul>
    <li>Informations agence</li>
    <li>Baréme d'honoraires</li>
    <li>Facture réseaux</li>
  </ul>

  <p>Administration</p>
  <ul>
    <li>Créer une agence</li>
    <li>Liste des agences</li>
    <li>Gestions passerelles</li>
    <li>Facture réseaux</li>
    <li></li>
  </ul>

  <button (click)="toggleFullScreenMenu()" class="close-button">
    <img
      alt="Fermer le menu"
      height="30px"
      src="./assets/icons/arrow.svg"
      width="30px" />
  </button>
</div>
