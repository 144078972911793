import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppUpdateService } from '@src/shared/pwa-update/app-update.service';
import { GoogleMapsService } from '@libs/google-places-autocomplete/google-maps.service';
import { ViewportService } from '@src/shared/viewport.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showNavbar = false;
  showUpdate = false;
  showCountdown = false;
  countdownTime = 60 * 5;
  countdownMessage = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appUpdateService: AppUpdateService,
    private cd: ChangeDetectorRef,
    private googleMapsService: GoogleMapsService,
    private viewportService: ViewportService
  ) {}

  ngOnInit(): void {
    this.googleMapsService.loadScript();
    this.appUpdateService.isUpdateAvailable$.subscribe(isUpdateAvailable => {
      if (isUpdateAvailable) {
        this.showCountdown = true;
        const intervalId = setInterval(() => {
          if (this.countdownTime > 0) {
            this.countdownTime--;
            this.cd.detectChanges();
            this.countdownMessage = `Une mise à jour est disponible. L'application sera mise à jour dans ${this.countdownTime} secondes.`;
          } else {
            clearInterval(intervalId);
            this.showUpdate = true;
            this.showCountdown = false;
            this.cd.detectChanges();
          }
        }, 1000);
      }
    });
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.root)
      )
      .subscribe(route => {
        let currentRoute = route;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        this.showNavbar = !currentRoute.snapshot.data['hideNavbar'];
      });
  }

  refresh(): void {
    window.location.reload();
  }
}
